import DatePicker from "ui_components/Bookings/DatePicker"
import dayjs, { Dayjs } from "dayjs"
import { useMemo, useState } from "react"
import {
  ChevronRightIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/20/solid"
import { TextFieldNew, PhoneNumberFieldNew } from "ui_components/UI"
import { useForm } from "react-hook-form"
import { useVisitorCountry } from "hooks"
import { useRouter } from "next/router"
import { trpc } from "lib/trpc"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { mixpanel } from "lib/mixpanel"
import Image from "next/image"
import { toast } from "react-toastify"
import { ExtraQuestions } from "@prisma/client"
import { ClipLoader } from "react-spinners"
import { isPossiblePhoneNumber } from "react-phone-number-input"

dayjs.extend(localizedFormat)

export function BookingMain({
  isHomePage,
  selectedEventTypeId,
  setSelectedEventTypeId,
  slug,
}: {
  isHomePage: boolean
  selectedEventTypeId: string | null
  setSelectedEventTypeId: (eventTypeId: string | null) => void
  slug: string
}) {
  const router = useRouter()

  const [userBookedAppointment, setUserBookedAppointment] = useState(false)

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>()
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>()

  const {
    data: bookingSiteInformation,
    isLoading: isLoadingBookingSiteInformation,
  } = trpc.bookings.getBookingSiteInformationFromSlug.useQuery(slug, {
    onError: (error) => {
      if (error.message === "BOOKING_SITE_NOT_FOUND") {
        router.replace("/404")
      }

      toast.error(
        "Hubo un error cargando la información de la página de reservas"
      )
    },
  })

  const { data: availableSlots, isLoading: isLoadingAvailableSlots } =
    trpc.bookings.getAvailableDays.useQuery(
      { eventTypeId: selectedEventTypeId! },
      {
        enabled: selectedEventTypeId != null,
        onError: (error) => {
          if (
            error.data?.stack?.includes("Couldnt find available time for user")
          ) {
            toast.error(
              "El dueño de esta página no ha definido un horario para este tipo de cita"
            )
          }
        },
      }
    )

  const selectedEventType = bookingSiteInformation?.eventTypes.find(
    (eventType) => eventType.id === selectedEventTypeId
  )

  const dayToAvailableSlotsMap = useMemo(() => {
    if (availableSlots == null) {
      return {}
    }

    return availableSlots.reduce((acc, slot) => {
      const slotDate = dayjs(slot.start).format("YYYY-MM-DD")

      if (acc[slotDate] == null) {
        acc[slotDate] = []
      }

      acc[slotDate].push(dayjs(slot.start))

      return acc
    }, {})
  }, [availableSlots])

  if (isLoadingBookingSiteInformation) {
    return (
      <div className=" flex min-h-screen items-center justify-center">
        <ClipLoader
          cssOverride={{
            height: "50px",
            width: "50px",
          }}
          color="rgb(59, 195, 211)"
        ></ClipLoader>
      </div>
    )
  }

  const useMilitaryTimeFormat =
    selectedEventType?.calendar.useMilitaryTimeFormat ?? false

  return (
    <div className="mx-auto w-full max-w-2xl rounded-lg shadow">
      <div className="relative flex flex-col">
        {selectedEventTypeId != null && isLoadingAvailableSlots && (
          <div className="absolute bottom-0 top-0 flex w-full items-center justify-center">
            <ClipLoader
              cssOverride={{
                height: "50px",
                width: "50px",
              }}
              color="rgb(59, 195, 211)"
            ></ClipLoader>
          </div>
        )}

        {bookingSiteInformation?.bookingSite != null && (
          <div className=" border-b px-4">
            <EventInformation
              bookingSite={bookingSiteInformation?.bookingSite}
            ></EventInformation>
          </div>
        )}

        {selectedEventTypeId == null && (
          <EventTypes
            eventTypes={bookingSiteInformation?.eventTypes ?? []}
            setSelectedEventTypeId={(eventTypeId) => {
              setSelectedEventTypeId(eventTypeId)
            }}
          ></EventTypes>
        )}

        <div
          className={`px-4 py-10 ${
            selectedDate == null && selectedEventTypeId != null
              ? "block"
              : "hidden"
          }`}
        >
          <div className="flex items-center ">
            <GoBackButton
              onClick={() => {
                setSelectedEventTypeId(null)
              }}
            ></GoBackButton>

            <h2 className="ml-4 text-base font-semibold">¿Qué día quieres?</h2>
          </div>

          <div className="mt-8">
            <PickDate
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              includedDates={Object.keys(dayToAvailableSlotsMap)}
            ></PickDate>
          </div>
        </div>
        <div
          className={`px-4 py-10 ${
            selectedDate != null && selectedTime == null ? "block" : "hidden"
          }`}
        >
          <div className="flex items-center ">
            <GoBackButton onClick={() => setSelectedDate(null)}></GoBackButton>

            <h2 className="ml-4 text-base font-semibold">
              Horarios disponibles -{" "}
              {selectedDate?.locale("es").format("DD MMMM, YYYY")}
            </h2>
          </div>

          {selectedDate && (
            <PickSlot
              selectedDate={selectedDate}
              setSelectedTime={setSelectedTime}
              slots={dayToAvailableSlotsMap[selectedDate.format("YYYY-MM-DD")]}
              useMilitaryTimeFormat={useMilitaryTimeFormat}
            ></PickSlot>
          )}
        </div>
        <div className={`${selectedTime != null ? "px-4 py-10" : "hidden"}`}>
          {!userBookedAppointment && (
            <div className="flex items-center ">
              <GoBackButton
                onClick={() => setSelectedTime(null)}
              ></GoBackButton>

              <h2 className="ml-4 text-base font-semibold">
                Finaliza tu reserva
              </h2>
            </div>
          )}

          {userBookedAppointment && (
            <div>
              <div className=" mx-auto flex items-center ">
                <div className="w-14 text-green-500">
                  <CheckCircleIcon></CheckCircleIcon>
                </div>
                <h1 className="ml-4 text-center text-xl font-bold">
                  Reserva exitosa
                </h1>
              </div>
            </div>
          )}

          <div className="mt-6">
            <ReservationDetails
              title={selectedEventType?.title}
              date={selectedTime
                ?.locale("es")
                .format("ddd, MMM D, YYYY h:mm A")}
            ></ReservationDetails>
          </div>

          {selectedTime && !userBookedAppointment && (
            <ReservationForm
              selectedTime={selectedTime}
              eventTypeId={selectedEventTypeId}
              eventType={selectedEventType}
              onUserBookedAppointment={() => {
                setUserBookedAppointment(true)
                mixpanel.track("Appointment booked", {
                  bookingSite: bookingSiteInformation?.bookingSite?.slug,
                  eventType: selectedEventType?.title,
                })
              }}
            ></ReservationForm>
          )}
        </div>
      </div>
    </div>
  )
}

function EventTypes({
  eventTypes,
  setSelectedEventTypeId,
}: {
  eventTypes: any[]
  setSelectedEventTypeId: (eventyTypeId: string) => void
}) {
  return (
    <div className=" px-4 py-10">
      <h2 className="mb-4 text-base  font-semibold">
        Reserva una cita con nosotros:
      </h2>
      <ul
        role="list"
        className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
      >
        {eventTypes.map((eventType) => (
          <li
            key={eventType.id}
            className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
          >
            <button
              className="flex w-full items-center text-left"
              onClick={() => setSelectedEventTypeId(eventType.id)}
            >
              <div className="flex min-w-0 gap-x-4 pr-10">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    <span className="absolute inset-x-0 -top-px bottom-0" />
                    {eventType.title}
                  </p>
                  <p className="mt-1 flex whitespace-pre-wrap text-xs leading-5 text-gray-500 hover:underline ">
                    {eventType.description}
                  </p>
                </div>
              </div>
              <div className="ml-auto flex shrink-0 items-center gap-x-4">
                <ChevronRightIcon
                  className="h-5 w-5 flex-none text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

function GoBackButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      onClick={onClick}
      className="flex h-8 w-10 items-center justify-center rounded border"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="h-6 w-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
        />
      </svg>
    </button>
  )
}

function EventInformation({ bookingSite }: any) {
  const [imageError, setImageError] = useState(false)

  if (bookingSite == null) {
    return null
  }

  return (
    <div className="flex gap-4 py-4">
      {/* <img
        className="h-16 "
        src={bookingSite.avatarImageUrl}
        alt=""
      /> */}
      <div style={{ minWidth: "64px" }}>
        {imageError ? (
          <div
            style={{ width: "64px", height: "64px" }}
            className="rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500"
          ></div>
        ) : (
          <Image
            alt={bookingSite.title}
            className="rounded-full object-contain shadow"
            style={{ width: "64px", height: "64px" }}
            src={bookingSite.avatarImageUrl}
            width={64}
            height={64}
            onError={() => setImageError(true)}
          />
        )}
      </div>
      <div className="max-w-xl flex-auto">
        <h3 className=" font-semibold tracking-tight">{bookingSite.title}</h3>
        <p className="mt-2 whitespace-pre-wrap text-xs text-gray-600">
          {bookingSite.description}
        </p>
      </div>
    </div>
  )
}

function PickDate({
  selectedDate,
  setSelectedDate,
  includedDates,
}: {
  selectedDate?: Dayjs
  setSelectedDate: (date: Dayjs) => void
  includedDates: string[]
}) {
  const [browsingDate, setBrowsingDate] = useState<Dayjs>(
    dayjs().set("date", 1).set("hour", 0).set("minute", 0).set("second", 0)
  )

  return (
    <>
      <DatePicker
        locale="es"
        onChange={(newDate) => {
          setSelectedDate(newDate)
        }}
        onMonthChange={(newMonth) => {
          setBrowsingDate(newMonth)
        }}
        selected={selectedDate}
        browsingDate={browsingDate}
        includedDates={includedDates}
      ></DatePicker>
    </>
  )
}

function PickSlot({
  selectedDate,
  setSelectedTime,
  slots,
  useMilitaryTimeFormat,
}: {
  selectedDate: Dayjs
  setSelectedTime: (time: Dayjs) => void
  slots: any[]
  useMilitaryTimeFormat: boolean
}) {
  let content

  if (slots == null) {
    content = <p>No hay horarios disponibles para este día</p>
  } else if (slots.length === 0) {
    content = <p>No hay horarios disponibles para este día</p>
  } else {
    content = (
      <div>
        <div className="mb-2 text-right  text-gray-500">
          *Hora de {dayjs.tz.guess().split("/").pop()?.replace("_", " ")}
        </div>
        <ul className=" space-y-2 ">
          {slots.map((s: Dayjs, index) => (
            <li key={index} className="rounded border  text-center">
              <button
                className="block h-full w-full py-4 hover:shadow-md"
                onClick={() => setSelectedTime(s)}
              >
                {useMilitaryTimeFormat ? s.format("H:mm") : s.format("h:mm A")}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div className="flex  flex-col">
      <div className="mt-6">{content}</div>
    </div>
  )
}

function ReservationForm({
  selectedTime,
  eventTypeId,
  eventType,
  onUserBookedAppointment,
}: {
  selectedTime: Dayjs
  eventTypeId: any
  eventType: any
  onUserBookedAppointment: () => void
}) {
  const email = eventType.sendInvitationToGoogleCalendarEvent
    ? { email: "" }
    : {}

  const extraQuestionsForForm = eventType.extraQuestions.reduce(
    (acc: any, question: ExtraQuestions) => {
      acc[`extraQuestion-${question.id}`] = ""
      return acc
    },
    {}
  )

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      name: "",
      phoneNumber: "",
      ...email,
      ...extraQuestionsForForm,
    },
  })
  const { country } = useVisitorCountry()
  const mutation = trpc.bookings.createEvent.useMutation({
    onError: (err) => {
      if (err.message === "SLOT_NOT_FOUND") {
        alert(
          "Disculpa, pero ya no hay disponibilidad a la hora que escogiste. Escoge una nueva hora."
        )
        window.location.reload()
      } else {
        alert("Hubo un error creando tu cita")
      }
    },
    onSuccess: async (client) => {
      // alert("cita creada")
      // toast.success("Contacto creado")
      // await trpcUtils.customer.getClients.invalidate()
      // mixpanel.track("Create client")
      onUserBookedAppointment()
    },
  })

  const onSubmit = async (data: any) => {
    if (mutation.isLoading) {
      return
    }

    let extraQuestionsAnswers = {}

    if (eventType.extraQuestions && eventType.extraQuestions.length > 0) {
      eventType.extraQuestions.forEach((question: ExtraQuestions) => {
        extraQuestionsAnswers[question.id] =
          data[`extraQuestion-${question.id}`]
      })
    }

    if (data.phoneNumber == "" || data.phoneNumber == null) {
      return alert("Por favor ingresa tu número de WhatsApp")
    }

    if (!isPossiblePhoneNumber(data.phoneNumber)) {
      return alert("Por favor ingresa un número de WhatsApp válido")
    }

    const mutationObject = {
      phoneNumber: data.phoneNumber.replace("+", ""),
      name: data.name,
      eventTypeId: eventTypeId,
      event: {
        start: selectedTime.toDate().toISOString(),
      },
      email: data.email || "",
      extraQuestionsAnswers,
    }

    await mutation.mutate(mutationObject)
  }

  return (
    <div className="flex flex-col">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6 space-y-4">
          <TextFieldNew label="¿Cuál es tu nombre?">
            <input
              maxLength={36}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault()
              }}
              type="text"
              className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              {...register("name", {
                required: true,
                maxLength: {
                  value: 36,
                  message:
                    "El nombre del contacto no puede ser mayor a 36 caracteres",
                },
              })}
            />
          </TextFieldNew>
          <PhoneNumberFieldNew
            label="¿Cuál es tu WhatsApp?"
            control={control}
            country={country}
          ></PhoneNumberFieldNew>
          {eventType.sendInvitationToGoogleCalendarEvent && (
            <TextFieldNew label="¿Cuál es tu email?">
              <input
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault()
                }}
                type="email"
                className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...register("email", {
                  required: true,
                })}
              />
            </TextFieldNew>
          )}
          {eventType.extraQuestions.map((question: ExtraQuestions) => {
            return (
              <TextFieldNew label={question.question}>
                {question.type === "long_text" && (
                  <textarea
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault()
                    }}
                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    {...register(`extraQuestion-${question.id}`, {
                      required: true,
                    })}
                    rows={3}
                  />
                )}

                {question.type !== "long_text" && (
                  <input
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault()
                    }}
                    type={question.type === "text" ? "text" : "number"}
                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    {...register(`extraQuestion-${question.id}`, {
                      required: true,
                    })}
                  />
                )}
              </TextFieldNew>
            )
          })}

          <div className="flex justify-center pt-4">
            <button
              type="submit"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-2 text-lg font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              {mutation.isLoading && (
                <ClipLoader
                  cssOverride={{
                    height: "20px",
                    width: "20px",
                  }}
                  color="rgb(59, 195, 211)"
                ></ClipLoader>
              )}
              <span className="mx-2">Reservar</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

function ReservationDetails({ title, date }: { title: string; date: string }) {
  return (
    <div className="lg:col-start-3 lg:row-end-1">
      <div className="rounded-lg bg-gray-50 py-4 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="flex w-full flex-none gap-x-4  border-gray-900/5 px-6">
            <dt className="flex-none">
              <span className="sr-only">Client</span>
              <ArrowRightCircleIcon
                className="h-6 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd className="text-sm font-medium leading-6 text-gray-500">
              {title}
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <CalendarDaysIcon
                className="h-6 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd className="text-sm font-medium leading-6 text-gray-500">
              <time>{date}</time>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}
